<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">{{ $t('share.title') }}</h4>
        </div>
        <div v-if="invitation_code" class="border-bottom">
            <div class="pb-20 mb-10">
                <div class="p-20 d-flex-center">
                    <el-image width="200" height="200" :src="'/api/qrcode/invitation/code/'+invitation_code" />
                </div>
                <div class="font-16 color-p font-bold text-center mb-20">{{ $t('register.invitation_code') }} : {{ invitation_code }}</div>
                <div class="text-center">
                    <el-button type="success" size='mini' icon="el-icon-document-copy" plain @click="onCopy()">{{ $t('share.cic') }}</el-button>
                </div>
            </div>
        </div>
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">{{ $t('share.team.title') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchAwards()">{{ $t('base.refresh') }}</el-button>
        </div>
        <div v-loading="loadingAwards">
            <div class="d-flex-center px-10 border-bottom">
                <h5 class="my-15 mr-auto">{{ $t('share.team.ct1') }}</h5>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 pt-10 font-14">
                <el-col :span='6'>{{ $t('total') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_1') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_2') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_3') }}</el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-10 border-bottom font-14">
                <el-col :span='6'>{{ awards.total_child.total }}</el-col>
                <el-col :span='6'>{{ awards.total_child.level1 }}</el-col>
                <el-col :span='6'>{{ awards.total_child.level2 }}</el-col>
                <el-col :span='6'>{{ awards.total_child.level3 }}</el-col>
            </el-row>
            <div class="d-flex-center px-10 border-bottom">
                <h5 class="my-15 mr-auto">{{ $t('share.team.ct2') }}</h5>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 pt-10 font-14">
                <el-col :span='6'>{{ $t('total') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_1') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_2') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_3') }}</el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-10 border-bottom font-14">
                <el-col :span='6'>{{ awards.today_new_child.total }}</el-col>
                <el-col :span='6'>{{ awards.today_new_child.level1 }}</el-col>
                <el-col :span='6'>{{ awards.today_new_child.level2 }}</el-col>
                <el-col :span='6'>{{ awards.today_new_child.level3 }}</el-col>
            </el-row>
            <div class="d-flex-center px-10 border-bottom">
                <h5 class="my-15 mr-auto">{{ $t('share.team.ct3') }}</h5>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 pt-10 font-14">
                <el-col :span='6'>{{ $t('total') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_1') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_2') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_3') }}</el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-10 border-bottom font-14">
                <el-col :span='6'>{{ awards.today_income.total }}</el-col>
                <el-col :span='6'>{{ awards.today_income.level1 }}</el-col>
                <el-col :span='6'>{{ awards.today_income.level2 }}</el-col>
                <el-col :span='6'>{{ awards.today_income.level3 }}</el-col>
            </el-row>
            <div class="d-flex-center px-10 border-bottom">
                <h5 class="my-15 mr-auto">{{ $t('share.team.ct4') }} (USDT)</h5>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 pt-10 font-14">
                <el-col :span='6'>{{ $t('total') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_1') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_2') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_3') }}</el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-10 border-bottom font-14">
                <el-col :span='6'>{{ awards.total_income.total }}</el-col>
                <el-col :span='6'>{{ awards.total_income.level1 }}</el-col>
                <el-col :span='6'>{{ awards.total_income.level2 }}</el-col>
                <el-col :span='6'>{{ awards.total_income.level3 }}</el-col>
            </el-row>
            <div class="d-flex-center px-10 border-bottom">
                <h5 class="my-15 mr-auto">{{ $t('share.team.ct5') }} (USDT)</h5>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 pt-10 font-14">
                <el-col :span='6'>{{ $t('total') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_1') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_2') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_3') }}</el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-10 border-bottom font-14">
                <el-col :span='6'>{{ awards.total_child_usdt.total }}</el-col>
                <el-col :span='6'>{{ awards.total_child_usdt.level1 }}</el-col>
                <el-col :span='6'>{{ awards.total_child_usdt.level2 }}</el-col>
                <el-col :span='6'>{{ awards.total_child_usdt.level3 }}</el-col>
            </el-row>
            <div class="d-flex-center px-10 border-bottom">
                <h5 class="my-15 mr-auto">Total frist recharge (USDT)</h5>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 pt-10 font-14">
                <el-col :span='6'>{{ $t('total') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_1') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_2') }}</el-col>
                <el-col :span='6'>{{ $t('share.team.level_3') }}</el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-10 border-bottom font-14">
                <el-col :span='6'>{{ awards.total_child_frist_recharge.total }}</el-col>
                <el-col :span='6'>{{ awards.total_child_frist_recharge.level1 }}</el-col>
                <el-col :span='6'>{{ awards.total_child_frist_recharge.level2 }}</el-col>
                <el-col :span='6'>{{ awards.total_child_frist_recharge.level3 }}</el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import XEClipboard from 'xe-clipboard'
export default {
    data() {
        return {
            invitation_code: '',
            loadingData: true,
            loadingAwards: true,
            awards: {
                today_income: {
                    total: 0,
                    level1: 0,
                    level2: 0,
                    level3: 0
                },
                today_new_child: {
                    total: 0,
                    level1: 0,
                    level2: 0,
                    level3: 0
                },
                total_income: {
                    total: 0,
                    level1: 0,
                    level2: 0,
                    level3: 0
                },
                total_child: {
                    total: 0,
                    level1: 0,
                    level2: 0,
                    level3: 0
                },
                total_child_usdt: {
                    total: 0,
                    level1: 0,
                    level2: 0,
                    level3: 0
                },
                total_child_frist_recharge: {
                    total: 0,
                    level1: 0,
                    level2: 0,
                    level3: 0
                }
            }
        }
    },
    created: function() {
        this.fatchData()
        this.fatchAwards()
    },
    methods: {
        fatchData: function () {
            this.loadingData = true
            this.$request.post('user/get_invitation_code', this.query).then(({ data }) => {
                this.invitation_code = data
            })
        },
        onCopy: function(){
            if(XEClipboard.copy(this.invitation_code)){
                this.$message({
                    message: this.$t('copied'),
                    type: 'success'
                })
            }
        },
        fatchAwards: async function () {
            this.loadingAwards = true
            const { data } = await this.$request.get('user/awards')
            this.awards = data
            this.loadingAwards = false
        },
    }
}
</script>
<style lang="scss" scoped>
//
</style>